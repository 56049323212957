import React from "react";
import Image from "next/image";
import cap from "@/public/images/blackCap.svg";
import Link from "next/link";
import { Faculty } from "@/types/Faculty";

interface Props {
  faculty: Faculty;
  locale: "ar" | "en";
}

const FacultyCard: React.FC<Props> = ({ faculty, locale }) => {
  return (
    // <div className="w-full">
    //   <Link
    //     href={`/${locale}/faculities/?faculty=${faculty?.id}`}
    //     className="w-full relative rounded-[10px]"
    //   >
    //     {faculty.image ? (
    //       <img
    //         src={faculty.image}
    //         alt={faculty.name[locale]}
    //         className="rounded-[10px] object-cover w-full min-h-[530px] h-[530px] max-h-[530px]"
    //       />
    //     ) : (
    //       ""
    //     )}
    //     <div className="flex items-center gap-3 bg-white absolute start-5 top-5 py-2 px-5 rounded-[10px] w-fit">
    //       <Image
    //         src={cap}
    //         alt="cap"
    //       />
    //       <p className="text-16 text-darkText">
    //         {faculty.universities_count}{" "}
    //         {locale === "ar" ? "جامعه متوفره" : "Available Universities"}
    //       </p>
    //     </div>
    //     <div className="flex flex-col items-center gap-3 bg-white absolute left-1/2 -translate-x-1/2 bottom-5 py-5 px-5 rounded-[10px] w-[90%]">
    //       <p className="text-20 text-darkText font-[600]">
    //         {faculty.name[locale]}
    //       </p>
    //       {faculty.short_description && (
    //         <p className="text-16 text-descriptionText text-center">
    //           {faculty.short_description[locale]}
    //         </p>
    //       )}
    //     </div>
    //   </Link>
    // </div>

    <Link
      href={`/${locale}/faculities/?faculty=${faculty?.id}`}
      className="group w-full min-h-[530px] h-[530px] max-h-[530px] rounded-[10px] relative overflow-hidden flex flex-col justify-between"
    >
      {/* Background image scaling effect */}
      <div
        className="absolute inset-0 bg-cover bg-center transition-transform duration-300 group-hover:scale-110"
        style={{
          backgroundImage: `url(${faculty.image})`,
        }}
      />

      <div className="flex justify-between p-5 relative z-10">
        <div className="flex items-center gap-3 bg-white py-2 px-5 rounded-[10px]">
          <Image src={cap} alt="cap" />
          <p className="text-16 text-darkText">
            {faculty.faculty_count}{" "}
            {locale === "ar" ? "كليه متوفره" : "Available Faculties"}
          </p>
        </div>
      </div>

      <div className="flex flex-col min-h-40 items-center gap-3 bg-white py-5 px-5 rounded-[10px] w-[90%] mx-auto mb-5 relative z-10">
        <p className="text-20 text-darkText font-[600]">
          {faculty.name[locale]}
        </p>
        {faculty.short_description && (
          <p className="text-16 text-descriptionText text-center line-clamp-3">
            {faculty.short_description[locale]}
          </p>
        )}
      </div>
    </Link>
  );
};

export default FacultyCard;

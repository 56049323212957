import React from "react";
import time from "@/public/images/time.svg";
import view from "@/public/images/view.svg";
import articleImage from "@/public/images/articleImage.jpg";
import Image from "next/image";
import MyLink from "../myLink/MyLink";
import { Blog } from "@/types/blog";
import { formatDistanceToNow } from "date-fns";
import { ar, enUS } from "date-fns/locale";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import { formatNumber } from "@/utils/format viwes number/formatNumber";

type IndexProps = {
  blog: Blog;
};

const Index = ({ blog }: IndexProps) => {
  const { locale }: { locale: "ar" | "en" } = useTranslate();
  const lang: "ar" | "en" = locale as "ar" | "en";

  const timeLocale = lang === "ar" ? ar : enUS;
  const timeAgo = formatDistanceToNow(new Date(blog.created), {
    addSuffix: true,
    locale: timeLocale,
  });

  return (
    <div className="h-full overflow-hidden group  w-full bg-white border border-[#EBEBEB] rounded-[10px] p-3  flex flex-col gap-3 justify-between">
      <Image
        src={blog.image[locale] || articleImage}
        alt={blog.title[lang]}
        layout="responsive"
        width={700}
        height={225}
        className="rounded-[10px] cursor-pointer max-h-[225px] transition-transform duration-300 group-hover:scale-105 object-cover"
      />
      <div className=" flex items-center justify-between">
        <div className=" flex items-center gap-3">
          <Image src={time} alt="time" />
          <p className=" text-14 text-descriptionText">{timeAgo}</p>
        </div>
        <div className=" flex items-center gap-3">
          <Image src={view} alt="views" />
          <p className=" text-14 text-descriptionText">
            {formatNumber(blog?.views)} {lang === "ar" ? "مشاهدة" : "views"}
          </p>
        </div>
      </div>
      <p className=" text-20  text-darkText  font-[500] line-clamp-2 min-h-[60px]">
        {blog.title[lang]}
      </p>
      <p className=" text-16 text-descriptionText line-clamp-1 ">
        {blog.short_description[lang]}
      </p>
      <span className=" w-[90%] h-[1px] bg-[#EBEBEB] block" />
      <div className="flex gap-3 flex-wrap overflow-hidden line-clamp-1">
        {blog.tags.slice(0, 3).map((tag, index) => (
          <p
            key={index}
            className=" text-14 text-descriptionText border border-[#EBEBEB] rounded-full py-1 px-5 c"
          >
            {tag[lang].length < 15 ? tag[lang] : `${tag[lang].slice(0, 15)}...`}
          </p>
        ))}
      </div>
      <MyLink
        href={`/articles/${blog.id}`}
        className=" h-[58px] text-20 text-white bg-primaryColor rounded-[10px] flex items-center justify-center"
      >
        {lang === "ar" ? "قرائة المزيد" : "Read more"}
      </MyLink>
    </div>
  );
};

export default Index;
